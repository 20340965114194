import { useEffect, useState } from 'react';
import { GetStaticPaths, GetStaticProps } from 'next';
import NotFound from 'src/NotFound';
import Layout from 'src/Layout';
import {
  RenderingType,
  EditingComponentPlaceholder,
  StaticPath,
  ComponentPropsContext,
  SitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { handleEditorFastRefresh } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { SitecorePageProps } from 'lib/page-props';
import { sitecorePagePropsFactory } from 'lib/page-props-factory';
import { sitemapFetcher } from 'lib/sitemap-fetcher';

import OrderCloudSearchProvider from 'lib/configurations/sitecoreOrderCloudSearchProvider';
import SitecoreSetAllDiscoverRulesCover from 'lib/configurations/sitecoreSetAllDiscoverRulesCover';
import { CheckoutFormContextProvider } from 'lib/context/CheckoutFormContext';
import ComponentContext, { ComponentContextData } from 'lib/context/ComponentContext';
import GtmScript from 'src/helpers/GtmScript';
import OcProvider from 'src/redux/ocProvider';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
import { componentBuilder } from 'temp/componentBuilder';
import { AutoshipContextProvider } from 'lib/context/AutoshipComponentContext';
import { PersonalizationRulesProvider } from 'src/helpers/withPersonalization/providers/PersonalizationRulesProvider';
import { SiteName } from 'src/helpers/Constants';
import { BvTokenContextProvider } from 'components/BazarVoice/BazarVoiceToken';
import MaintenanceMode from 'components/MaintainanceMode/MaintainanceMode';

const SitecorePage = ({
  notFound,
  layoutData,
  headLinks,
  site,
  componentProps,
}: SitecorePageProps): JSX.Element => {
  useEffect(() => {
    // Since Sitecore editors do not support Fast Refresh, need to refresh editor chromes after Fast Refresh finished
    handleEditorFastRefresh();
  }, []);

  const isEE = useExperienceEditor();
  const isMaintainanceMode = process.env.NEXT_PUBLIC_MAINTAINANCE_MODE === 'true';

  useEffect(() => {
    if (!isMaintainanceMode && layoutData?.sitecore?.route?.name === '_500')
      window.history.replaceState(null, '', '/500');
    if (!isMaintainanceMode && layoutData?.sitecore?.route?.name === '_404')
      window.history.replaceState(null, '', '/404');
  }, []);

  const [componentContextData, setcomponentContextData] = useState<ComponentContextData>({});

  if (isMaintainanceMode) {
    return <MaintenanceMode />;
  }

  if (notFound || !layoutData?.sitecore?.route) {
    // Shouldn't hit this (as long as 'notFound' is being returned below), but just to be safe
    return <NotFound />;
  }

  const isEditing = layoutData?.sitecore.context.pageEditing;

  const isComponentRendering =
    layoutData.sitecore.context.renderingType === RenderingType.Component;

  return (
    <ComponentPropsContext value={componentProps}>
      <SitecoreContext
        componentFactory={componentBuilder.getComponentFactory({ isEditing })}
        layoutData={layoutData}
      >
        <PersonalizationRulesProvider>
          <OcProvider>
            {!isEE && <GtmScript />}
            <OrderCloudSearchProvider siteName={site.name as SiteName}>
              <ComponentContext.Provider
                value={{
                  componentContextData,
                  setcomponentContextData,
                }}
              >
                <BvTokenContextProvider>
                  <AutoshipContextProvider>
                    <CheckoutFormContextProvider>
                      {/* 
              Sitecore Pages supports component rendering to avoid refreshing the entire page during component editing.
              If you are using Experience Editor only, this logic can be removed, Layout can be left. 
              */}
                      {isComponentRendering ? (
                        <EditingComponentPlaceholder rendering={layoutData.sitecore.route} />
                      ) : (
                        <Layout layoutData={layoutData} headLinks={headLinks} />
                      )}
                    </CheckoutFormContextProvider>
                  </AutoshipContextProvider>
                </BvTokenContextProvider>
              </ComponentContext.Provider>
            </OrderCloudSearchProvider>
            <SitecoreSetAllDiscoverRulesCover />
          </OcProvider>
        </PersonalizationRulesProvider>
      </SitecoreContext>
    </ComponentPropsContext>
  );
  // Sitecore Pages supports component rendering to avoid refreshing the entire page during component editing.
  //       If you are using Experience Editor only, this logic can be removed, Layout can be left.
  // return isComponentRendering ? (
  //   <EditingComponentPlaceholder rendering={layoutData.sitecore.route} />
  // ) : (
  //   <Layout layoutData={layoutData} headLinks={headLinks} />
  // );
};

// This function gets called at build and export time to determine
// pages for SSG ("paths", as tokenized array).
export const getStaticPaths: GetStaticPaths = async (context) => {
  // Fallback, along with revalidate in getStaticProps (below),
  // enables Incremental Static Regeneration. This allows us to
  // leave certain (or all) paths empty if desired and static pages
  // will be generated on request (development mode in this example).
  // Alternatively, the entire sitemap could be pre-rendered
  // ahead of time (non-development mode in this example).
  // See https://nextjs.org/docs/basic-features/data-fetching/incremental-static-regeneration

  let paths: StaticPath[] = [];
  let fallback: boolean | 'blocking' = 'blocking';

  if (process.env.NODE_ENV !== 'development' && !process.env.DISABLE_SSG_FETCH) {
    try {
      // Note: Next.js runs export in production mode
      paths = await sitemapFetcher.fetch(context);
    } catch (error) {
      console.error('Error occurred while fetching static paths');
      console.error(error);
    }

    fallback = process.env.EXPORT_MODE ? false : fallback;
  }

  return {
    paths,
    fallback,
  };
};

// This function gets called at build time on server-side.
// It may be called again, on a serverless function, if
// revalidation (or fallback) is enabled and a new request comes in.
export const getStaticProps: GetStaticProps = async (context) => {
  console.time('getStaticProps:' + context.params?.path);
  const props = await sitecorePagePropsFactory.create(context);

  console.timeEnd('getStaticProps:' + context.params?.path);
  return {
    props,
    // Next.js will attempt to re-generate the page:
    // - When a request comes in
    // - At most once every 5 seconds
    revalidate: parseInt(process.env.ISR_REVALIDATE_TIME || '5', 10), // In seconds
    notFound: props.notFound, // Returns custom 404 page with a status code of 404 when true
  };
};

export default SitecorePage;
