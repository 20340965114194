import { GTMLabels, defaultBuyer } from 'src/helpers/Constants';
import Script from 'next/script';
import React, { useEffect, useState } from 'react';
import { useOcSelector } from 'src/redux/ocStore';

const GtmScript = (): JSX.Element => {
  const gtmID = useOcSelector((state) => state?.otherSettingSlice?.data?.gtmId?.value);
  const ocUser = useOcSelector((state) => state.ocUser);
  const isAnonymous = useOcSelector((s) => s.ocAuth?.isAnonymous);
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const [isUserData, setIsUserEvent] = useState(false);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];

    // Push the initial user data event
    gtmID &&
      ocUser &&
      ocUser?.user?.ID &&
      myStoreData?.storeId &&
      (setIsUserEvent(true),
      window.dataLayer.push({
        loggedIn: isAnonymous ? 'N' : 'Y',
        storeId: myStoreData?.storeId,
        unityId: !isAnonymous
          ? ocUser?.user?.xp?.UnityId?.toString()?.startsWith(GTMLabels?.NRM)
            ? ocUser?.user?.xp?.UnityId
            : GTMLabels?.NRM + ocUser?.user?.xp?.UnityId
          : undefined,
        userId: !isAnonymous
          ? ocUser?.user?.ID !== defaultBuyer?.ID
            ? ocUser?.user?.ID
            : undefined
          : undefined,
        visitorType: isAnonymous ? GTMLabels?.guest : GTMLabels?.loggedIn,
      }));
  }, [ocUser?.user?.ID, myStoreData?.storeId, gtmID]);

  if (gtmID === '' || gtmID === undefined || gtmID === null) {
    return <></>;
  }

  return (
    <>
      {/* <!-- Google Tag Manager --> */}
      {isUserData && (
        <>
          <Script
            id="GTM_SCRIPT"
            dangerouslySetInnerHTML={{
              __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmID}');
          `,
            }}
          />
          <noscript
            dangerouslySetInnerHTML={{
              __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmID}"
          height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
            }}
          />
        </>
      )}
    </>
  );
};

export default GtmScript;
